body {
  min-height: 100vh;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "uxwing-iconsfont";
  src: url("../fonts/uxwing-iconsfont.eot?v=1.0");
  src: url("../fonts/uxwing-iconsfont.eot?v=1.0#iefix")
      format("embedded-opentype"),
    url("../fonts/uxwing-iconsfont.ttf?v=1.0") format("truetype"),
    url("../fonts/uxwing-iconsfont.woff?v=1.0") format("woff"),
    url("../fonts/uxwing-iconsfont.svg?v=1.0#uxwing-iconsfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.test::before {
  content: "\e919";
  font-family: "uxwing-iconsfont";
}
/* @media print {
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
} */

/* 
#root {
  background-color: #555555;
  position: absolute;
  width: 100%;
  height: 100%;
} */
